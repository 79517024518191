<template>
    <div>
        <el-input
            type="textarea"
            :rows="7"
            placeholder="请输入第三方平台订单号,一行一个订单号"
            v-model="string">
        </el-input>
        <el-button @click="changeString()">转化</el-button>
        <el-input
            type="textarea"
            :rows="7"
            placeholder="请输入第三方平台订单号,一行一个订单号"
            v-model="numString">
        </el-input>
    </div>
</template>
<script>
export default {
  data () {
    return {
      string: '',
      numString: ''
    }
  },
  methods: {
    changeString () {
      console.log(this.string.match(/1+\d{10}/g))
      this.numString = this.string.match(/1+\d{10}/g).join(',')
    }
  }
}
</script>
